import React from "react"
import styled from "styled-components"

// Components
import ContentWrapper from "../components/ContentWrapper"

// Styles
import mq from "../styles/breakpoints"
import colors from "../styles/colors"
import textStyles from "../styles/textStyles"

// Types
import { ITeamMember } from "src/prismic-types/team"

interface IProps {
  title: string
  team: ITeamMember[]
  background?: string
}

const TeamComponent: React.FC<IProps> = ({ title, team, background }) => (
  <Section background={background}>
    <StyledContentWrapper>
      <Title>{title}</Title>
      <Team>
        {team.map((member, index) => (
          <Member key={`team-${index}`}>
            <Image src={member.image.url} />
            <Name>{member.memberName}</Name>
            <Role>{member.memberRole}</Role>
          </Member>
        ))}
      </Team>
    </StyledContentWrapper>
  </Section>
)

export default TeamComponent

const Section = styled.section<{ background?: string }>`
  position: relative;
  padding: 64px 0;
  background: ${({ background }) =>
    background ? background : colors.backgroundBlue};

  ${mq.from.M`
    padding: 120px 0;
  `}
`

const StyledContentWrapper = styled(ContentWrapper)`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 64px;
`

const Title = styled.h3`
  color: ${colors.purpleLight};
  ${textStyles.titleM};
`

const Team = styled.div`
  display: grid;
  grid-template-columns: 1fr; // By default (for very small screens), it's 1 column
  grid-column-gap: 48px;
  gap: 48px;

  ${mq.from.S`
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 48px;
  `}

  ${mq.from.M`
    grid-template-columns: repeat(3, 1fr); // Three columns for medium and above
  `}
`

const Member = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 300px;
`

const Image = styled.img`
  width: 100%;
  border-radius: 10px;

  width: 254px;
  height: 350px;
`

const Name = styled.h5`
  color: ${colors.main["100"]};
  ${textStyles.titleS};
  text-align: center;
  ${mq.from.M`
    font-size: 22px;
  `}
  width: 254px;
`

const Role = styled.p`
  color: ${colors.main["300"]};
  ${textStyles.body};
  text-align: center;
  text-transform: uppercase;
  margin: 8px 0 0 0;
  ${mq.from.M`
  font-size: 16px;
`}
`
