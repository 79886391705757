import React from "react"
import styled from "styled-components"

// Styling
import mq from "../styles/breakpoints"
import colors from "../styles/colors"
import textStyles from "../styles/textStyles"

// Components
import ContentWrapper from "./ContentWrapper"

// Types
import { IAboutUsContent } from "src/prismic-types/about"

interface IProps {
  className?: string
  data: IAboutUsContent[]
}

const AboutSection: React.FC<IProps> = ({ className, data }) => {
  return (
    <Container className={className}>
      <StyledContentWrapper>
        {data.map((section, index) => (
          <Section key={`${section.left_text?.text}-${index}`}>
            {section.image?.url ? (
              <ProfileAndTitle>
                <ImageWrapper>
                  <img src={section.image.url} alt={section.image.alt} />
                </ImageWrapper>
                <Title
                  dangerouslySetInnerHTML={{
                    __html: section?.left_text?.html || "",
                  }}
                />
              </ProfileAndTitle>
            ) : (
              <Paragraph
                isFullWidth={section.allow_full_width === "left"}
                dangerouslySetInnerHTML={{
                  __html: section?.left_text?.html || "",
                }}
              />
            )}

            {section.allow_full_width !== "left" && (
              <Paragraph
                dangerouslySetInnerHTML={{
                  __html: section?.right_text?.html || "",
                }}
              />
            )}
          </Section>
        ))}
      </StyledContentWrapper>
    </Container>
  )
}

export default AboutSection

const Container = styled.section`
  position: relative;
  padding: 64px 0 64px 0;
`

const StyledContentWrapper = styled(ContentWrapper)`
  display: flex;
  flex-direction: column;
  row-gap: 64px;
`

const Section = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: start;
  row-gap: 16px;

  ${mq.from.M`
    column-gap: 16px;
    flex-direction: row;
  `}
`

const ProfileAndTitle = styled.div`
  display: flex;
  align-items: flex-start;
  column-gap: 16px;
  min-width: 50%;
`

const Title = styled.div`
> h2 {
  color: ${colors.main["200"]};
  ${textStyles.titleM};

  ${mq.from.M`
  font-size: 40px !important;
  line-height: 52px !important;
`}
`
const ImageWrapper = styled.div`
  > img {
    min-width: 70px;
    height: 70px;

    ${mq.from.M`
        min-width: 144px;
        height: 144px;
    `}
  }
  border-radius: 50%;
`

const Paragraph = styled.div<{ isFullWidth?: boolean }>`
  ${({ isFullWidth }) =>
    isFullWidth
      ? `
    max-width: 75%;`
      : `
    min-width: 50%;
    `}}
  > p {
    margin: 0px;
    color: ${colors.main["200"]};
    ${textStyles.body};

    ${mq.from.M`
    font-size: 20px !important;
    line-height: 36px !important;
  `}
    }
  }

  > h3 {
    color: ${colors.main["300"]};
    ${textStyles.titleM};
    text-align: left;
    position: relative;
  }
`
